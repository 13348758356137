import { sepolia } from "viem/chains";
export const SEPOLIA = {
    id: sepolia.id,
    name: sepolia.name,
    rpc: {
        http: ["https://eth-sepolia.g.alchemy.com/v2/epCE1Pxcalf926LU7xkA5lvF5T5MluTC"],
        wss: ["wss://eth-sepolia.g.alchemy.com/v2/epCE1Pxcalf926LU7xkA5lvF5T5MluTC"],
    },
    explorer: "https://sepolia.etherscan.io",
    isTestnet: true,
    pollingInterval: 5_000,
    viemConfig: sepolia,
};
