import { createContext, FC, ReactNode, useState, useEffect, useContext } from "react"
import { WalletState } from "../domain/ethereum/Models/WalletState"
import { DomainEventDetail, DomainEvents } from "../domain/_kernel/Events"
import { TransactionList } from "../domain/ethereum/Models/TransactionList"
import { TransactionType } from "../domain/ethereum/Models/Transaction"
import { useEthereum } from "./EthereumContext"

interface TransactionsContextInterface {
  transactions: TransactionList
  submittedTransactionType: TransactionType | null
  isSubmitting: boolean
}

const initialState: TransactionsContextInterface = {
  transactions: TransactionList.empty(),
  submittedTransactionType: null,
  isSubmitting: false,
}

const Context = createContext<TransactionsContextInterface>(initialState)

export const TransactionsContext: FC<{ children: ReactNode }> = ({ children }) => {
  const { wallet } = useEthereum()
  const [transactions, setTransactions] = useState<TransactionList>(initialState.transactions)
  const [submittedTransactionType, setSubmittedTransactionType] = useState<TransactionType | null>(null)

  const handleSubmitTransaction = (e: CustomEvent<DomainEventDetail[DomainEvents.SUBMIT_TRANSACTION]>) => {
    setSubmittedTransactionType(e.detail.transactionType)
  }

  const handleSubmitTransactionError = () => {
    setSubmittedTransactionType(null)
  }

  const handleSetTransaction = () => {
    updateTransactions()
    setSubmittedTransactionType(null)
  }

  const updateTransactions = () => {
    window.domain.GetTransactionsEthereumUseCase.execute()
      .then(setTransactions)
      .catch(() => setTransactions(TransactionList.empty()))
  }

  // Transactions initial load
  useEffect(() => {
    if (wallet.status !== WalletState.STATUS.CONNECTED) return
    updateTransactions()
  }, [wallet])

  // Wait pending transactions
  useEffect(() => {
    transactions.pending.forEach((transaction) => {
      window.domain.WaitForTransactionEthereumUseCase.execute({ transaction }).then(updateTransactions)
    })
  }, [transactions])

  // Event listeners
  useEffect(() => {
    window.addEventListener(DomainEvents.SET_TRANSACTION, handleSetTransaction)
    window.addEventListener(DomainEvents.SUBMIT_TRANSACTION, handleSubmitTransaction as EventListener)
    window.addEventListener(DomainEvents.SUBMIT_TRANSACTION_ERROR, handleSubmitTransactionError)

    return () => {
      window.removeEventListener(DomainEvents.SET_TRANSACTION, handleSetTransaction)
      window.removeEventListener(DomainEvents.SUBMIT_TRANSACTION, handleSubmitTransaction as EventListener)
      window.removeEventListener(DomainEvents.SUBMIT_TRANSACTION_ERROR, handleSubmitTransactionError)
    }
  }, [])

  return (
    <Context.Provider
      value={{
        transactions,
        submittedTransactionType,
        isSubmitting: Boolean(submittedTransactionType),
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useTransactions = function () {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error(`useTransactions must be used within a TransactionsContextProvider`)
  }
  return context
}
