import { Transaction, TransactionType } from "../ethereum/Models/Transaction"

export enum DomainEvents {
  SET_TRANSACTION = "SET_TRANSACTION",
  SUBMIT_TRANSACTION = "SUBMIT_TRANSACTION",
  SUBMIT_TRANSACTION_ERROR = "SUBMIT_TRANSACTION_ERROR",
  SUCCESS_TRANSACTION = "SUCCESS_TRANSACTION",
}

export type DomainEventDetail = {
  [DomainEvents.SET_TRANSACTION]: { transaction: Transaction }
  [DomainEvents.SUBMIT_TRANSACTION]: { transactionType: TransactionType }
  [DomainEvents.SUBMIT_TRANSACTION_ERROR]: { transactionType: TransactionType }
  [DomainEvents.SUCCESS_TRANSACTION]: { transaction: Transaction }
}

export const dispatchDomainEvent = <T extends DomainEvents>(event: T, detail: DomainEventDetail[T]) => {
  window.dispatchEvent(new CustomEvent(event, { detail }))
}
