// src/ThemeContext.tsx
import React, { createContext, useState, useEffect, ReactNode, useContext } from "react"
import { CurrenciesAllowedEnum } from "@tutellus/core-contracts-ifaces"

const THEME_MODE_STORAGE_KEY = "theme-mode"
export const CURRENCY_STORAGE_KEY = "currency"

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

interface LayoutContextInterface {
  themeMode: ThemeMode
  setThemeMode: (mode: ThemeMode) => void
  currencyID: CurrenciesAllowedEnum
  setCurrencyID: (currency: CurrenciesAllowedEnum) => void
}

const initialState: LayoutContextInterface = {
  themeMode: ThemeMode.LIGHT,
  setThemeMode: () => {},
  currencyID: CurrenciesAllowedEnum.USD,
  setCurrencyID: () => {},
}

interface LayoutProviderProps {
  children: ReactNode
}

const Context = createContext<LayoutContextInterface>(initialState)

export const LayoutContext: React.FC<LayoutProviderProps> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(initialState.themeMode)
  const [currencyID, setCurrencyID] = useState<CurrenciesAllowedEnum>(CurrenciesAllowedEnum.USD)

  // Theme Mode
  useEffect(() => {
    const savedTheme = localStorage.getItem(THEME_MODE_STORAGE_KEY) || ThemeMode.LIGHT
    setThemeMode(savedTheme as ThemeMode)
  }, [])

  const handleSetThemeMode = (mode: ThemeMode) => {
    setThemeMode(mode)
    localStorage.setItem(THEME_MODE_STORAGE_KEY, mode)
  }

  // Currency
  useEffect(() => {
    const savedCurrency = localStorage.getItem(CURRENCY_STORAGE_KEY) || CurrenciesAllowedEnum.USD
    setCurrencyID(savedCurrency as CurrenciesAllowedEnum)
  }, [])

  const handleSetCurrency = (currency: CurrenciesAllowedEnum) => {
    setCurrencyID(currency)
    localStorage.setItem(CURRENCY_STORAGE_KEY, currency)
  }

  return (
    <Context.Provider
      value={{ themeMode, setThemeMode: handleSetThemeMode, currencyID, setCurrencyID: handleSetCurrency }}
    >
      {children}
    </Context.Provider>
  )
}

export const useLayout = function () {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error(`useLayout must be used within a LayoutContextProvider`)
  }
  return context
}
