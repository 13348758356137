import { polygon } from "viem/chains";
export const POLYGON = {
    id: polygon.id,
    name: polygon.name,
    rpc: {
        http: ["https://rpc.tenderly.co/fork/f2761199-5361-49a5-8eb4-e474a64353a4"],
    },
    explorer: "https://polygonscan.com/",
    isTestnet: false,
    pollingInterval: 15_000,
    viemConfig: polygon,
};
