import { createConfig, http } from "@wagmi/core"
import { CHAIN_CONFIG } from "../chain"

export const wagmiConfig = createConfig({
  chains: [CHAIN_CONFIG.viemConfig],
  connectors: [
    // metaMask({
    //   dappMetadata: {
    //     name: "Tutellus",
    //   },
    // }),
    // safe(),
    // walletConnect({ projectId: import.meta.env.VITE_WALLETCONNECT_PROJECT_ID }),
    // injected(),
  ],
  transports: {
    [CHAIN_CONFIG.id]: http(CHAIN_CONFIG.rpc.http[0]),
  },
})
