import { createContext, FC, ReactNode } from "react"
import { Domain } from "../domain"

export interface InnerContext {
  domain: Domain
}

window.domain =
  window.domain ??
  Domain.create({
    CHAIN_ID: import.meta.env.VITE_CHAIN_ID,
    APP_NAME: "Tutellus",
    APP_ICON: "https://d2xl4phllkk8g3.cloudfront.net/images/turinlabs_icon_40.svg",
    APP_LOGO: "https://d2xl4phllkk8g3.cloudfront.net/images/turinlabs_logo.svg",
    APP_DESC: "Tutellus",
    GRAPHQL_URI: import.meta.env.VITE_GRAPHQL_URI,
    MIGRATION_GRAPHQL_URI: import.meta.env.VITE_MIGRATION_GRAPHQL_URI,
    PROJECT_KEY: import.meta.env.VITE_PROJECT_KEY,
    ZERODEV_PROJECT_ID: import.meta.env.VITE_ZERODEV_PROJECT_ID,
    ZERODEV_BUNDLER_RPC: import.meta.env.VITE_ZERODEV_BUNDLER_RPC,
    ZERODEV_PAYMASTER_RPC: import.meta.env.VITE_ZERODEV_PAYMASTER_RPC,
    ZERODEV_PASSKEY_URL: import.meta.env.VITE_ZERODEV_PASSKEY_URL,
    VITE_OPENSEA_URL: import.meta.env.VITE_OPENSEA_URL,
  })

const innerContext: InnerContext = {
  domain: window.domain,
}

const Context = createContext<InnerContext>(innerContext)

export const DomainContext: FC<{ children: ReactNode }> = ({ children }) => {
  return <Context.Provider value={innerContext}>{children}</Context.Provider>
}
