import React from "react"
import ReactDOM from "react-dom/client"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom"
import { Component as ErrorPage } from "./pages/Error"
import { DomainContext, EthereumContext, LayoutContext, TransactionsContext } from "./context"
import { DataContext } from "./context/DataContext"
import "./i18n.config"
import "./index.css"
import { LoadingBar } from "@tutellus/tutellus-components"

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="migration" id="migration" lazy={async () => import("./pages/Migration")} />
      <Route
        element={
          <DataContext>
            <Outlet />
          </DataContext>
        }
      >
        <Route path="connect" id="connect" lazy={async () => import("./pages/Connect")} />
        <Route id="root" lazy={async () => import("./pages/Root")} errorElement={<ErrorPage />}>
          <Route index lazy={async () => import("./pages/Dashboard")} />
          <Route path="swap" lazy={async () => import("./pages/Swap")} />
          <Route path="wallet" lazy={async () => import("./pages/Wallet")} />
          <Route path="buy" lazy={async () => import("./pages/Buy")} />
          <Route path="liquidity" lazy={async () => import("./pages/Liquidity")} />
          <Route path="liquidity/:poolID" lazy={async () => import("./pages/PoolRoot")}>
            <Route path="deposit" lazy={async () => import("./pages/PoolDeposit")} />
            <Route path="withdraw" lazy={async () => import("./pages/PoolWithdraw")} />
          </Route>
          <Route path="vote/:syntheticID" id="staking-root" lazy={async () => import("./pages/StakingRoot")}>
            <Route index element={<Navigate to="information" />} />
            <Route path="information" lazy={async () => import("./pages/StakingInfo")} />
            <Route path="voting" lazy={async () => import("./pages/StakingVotes")} />
            <Route path="generate" lazy={async () => import("./pages/StakingGenerate")} />
            <Route path="rewards" lazy={async () => import("./pages/StakingRewards")} />
          </Route>
        </Route>
      </Route>
    </>,
  ),
  {
    basename: "/", // Añade esta configuración
  },
)

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <DomainContext>
      <EthereumContext>
        <TransactionsContext>
          <LayoutContext>
            <RouterProvider
              router={router}
              fallbackElement={
                <LoadingBar
                  mode="dark"
                  isFullScreen
                  icon={
                    <img src="https://cdn.prod.website-files.com/65f861746098e48e9c7538b0/672b970ea733a82db5bc5c0a_gt3-iso.svg"></img>
                  }
                />
              }
            />
          </LayoutContext>
        </TransactionsContext>
      </EthereumContext>
    </DomainContext>
  </React.StrictMode>,
)

// Añade esto para debug
//router.subscribe((state) => {
//  console.log("Navigation state:", state)
//})
